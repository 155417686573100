import React, {useState, useEffect} from 'react';
import queryString from "query-string";
import FecthingSpinner from "../../Shared/FecthingSpinner";
import httpService from "../../../services/http.service";
import AppBreadcrumb from "../../Shared/AppBreadcrumb";
import {useFormik} from "formik";
import * as Yup from "yup";
import useAlert from "../../../hooks/useAlert";
import AppAlert from "../../Shared/AppAlert";
import {Form, FormGroup} from "reactstrap";
import AppInput from "../../Shared/AppInput";
import MyOpinionLogo from "../../../assets/images/MyOpinion_Logo_Head.png";
import {extractResponseValidationErrors, getUserFullName} from "../../../utils/index";
import AppInputFile from "../../Shared/AppInputFile";
import {serialize} from "object-to-formdata";
import AppPagination from "../../Shared/AppPagination";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";

const AuthorizationList = ({authUser, history, location, match, ...props}) => {

    const inputFormInitialValues = {
        import_type: 'FORM',
        email_address_1: '',
        email_address_2: '',
        email_address_3: '',
    };

    const fileFormInitialValues = {
        import_type: 'FILE',
        file: '',
        create_accounts: false,
    };

    const query = queryString.parse(location.search);

    const [alert, setAlert, onClose] = useAlert();
    const [submitting, setSubmitting] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [exporting, setExporting] = useState(false);
    const [fetching, setFetching] = useState(true);
    const [emails, setEmails] = useState([]);
    const [file, setFile] = useState(null);
    const [modal, setModal] = useState({
        fileForm: false,
        inputForm: false
    });
    const [paginationData, setPaginationData] = useState({
        currentPage:  query?.page || 1,
        lastPage: Infinity,
        hasNext: true,
        hasPrevious: false,
        totalItems: Infinity
    });

    const navigateToUserProfile = (user) => {
        history.push(`/users/${user?.user_username}`);
    }

    const handlePagePaginate = async (direction) => {

        let page = parseInt(query?.page || 1);

        const nextPage = direction === 'previous' ? --page : ++page;

        const data = {
            page: nextPage
        };

        history.push(`/authorizations?${queryString.stringify(data)}`);

    }

    const fetchAuthorizations = async (params = {}, cb = () => null) => {
        const { data: response } = await httpService.get(`/authorization`, {
            params: {
                ...params,
                granted: 1
            }
        });
        setEmails(response.data);
        setPaginationData(state => ({
            ...state,
            currentPage: response?.meta?.current_page,
            lastPage: response?.meta?.last_page,
            totalItems: response?.meta?.total,
            hasNext: !!response?.links?.next,
            hasPrevious: !!response?.links?.prev
        }));
        cb();
    }

    const onExportFile = async () => {

        if(exporting) return;

        setExporting(true);

        try {
            const {data: file, headers} = await httpService.get(`/authorization`, {
                responseType: "blob",
                params: {
                    granted: 1,
                    action: 'export_csv'
                }
            });
            const url = window.URL.createObjectURL(new Blob([file]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', headers['filename']);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (e) {
            window.alert('Erreur de traitement');
        }
        finally {
            setExporting(false);
        }

    };

    const onDownloadFile = async () => {

        if(downloading) return;

        setDownloading(true);

        try {
            const {data: file} = await httpService.get(`/authorization/download`, {
                responseType: "blob"
            });
            const url = window.URL.createObjectURL(new Blob([file]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'IMPORT_FILE_BASE.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (e) {
            window.alert('Erreur de traitement');
        }
        finally {
            setDownloading(false);
        }

    }

    const onDeleteEmail = async (event, obj) => {
        event.stopPropagation();

        const id = obj?.email_id;

        if(!window.confirm('Êtes-vous sur ? \nSupprimer ' + obj?.email_address)) return;

        try {
            const { data: response } = await httpService.delete(`/authorization/${id}`);
            const newArr = emails.filter(obj => obj?.email_id != id);
            setEmails(newArr);
        } catch ({response}) {
            const {data, status} = response;

            if(response && status == 500) {
                window.alert('Erreur de traitement');
            }
            if(response && status == 404) {
                window.alert('Introuvable');
            }
        }
    };

    const onImportInputFormSubmit = async (values) => {
        setSubmitting(true);
        setAlert(null);

        try {
            const { data: response } = await httpService.post(`/authorization`, values);

            window.location.reload();
        }
        catch ({response}) {
            const {data, status} = response;

            if(response && status == 422) {
                setAlert({
                    type: 'danger',
                    message: extractResponseValidationErrors(data)[0]
                })
            }
            else if(response && status == 500) {
                setAlert({
                    type: 'danger',
                    message: 'Erreur de traitement, veuillez contacter les administrateurs'
                })
            }
        }
        finally {
            setSubmitting(false);
        }
    };

    const onCancelImportInputForm = () => {
        importInputForm.resetForm({...inputFormInitialValues});
        setAlert(null);
        setModal(state => ({...state, inputForm: false}));
    };

    const onImportFileFormSubmit = async (values) => {

        setSubmitting(true);
        setAlert(null);

        const formData = serialize(values, {
            indices: true,
            booleansAsIntegers: true
        });

        try {
            const { data: response } = await httpService.post(`/authorization`, formData);

            window.location.reload();
        }
        catch ({response}) {
            const {data, status} = response;

            if(response && status == 422) {
                setAlert({
                    type: 'danger',
                    message: extractResponseValidationErrors(data)[0]
                })
            }
            else if(response && status == 500) {
                setAlert({
                    type: 'danger',
                    message: 'Erreur de traitement, veuillez contacter les administrateurs'
                })
            }
        }
        finally {
            setSubmitting(false);
        }

    };

    const onCancelImportFileFormSubmit = () => {
        importFileForm.resetForm({...fileFormInitialValues});
        setAlert(null);
        setFile(null);
        setModal(state => ({...state, fileForm: false}));
    };

    const onFileSelect = (event) => {
        const {target} = event;
        const files = target.files;

        setFile(files[0]);

        importFileForm.setFieldValue('file', files[0], true);
        importFileForm.setFieldTouched('file', false);
    }

    const importInputForm = useFormik({
        initialValues: inputFormInitialValues,
        validationSchema: Yup.object().shape({
            email_address_1: Yup.string().ensure().email('Format incorrecte').required('Champ obligatoire'),
            email_address_2: Yup.string().ensure().email('Format incorrecte').nullable(),
            email_address_3: Yup.string().ensure().email('Format incorrecte').nullable(),
        }),
        onSubmit: onImportInputFormSubmit
    });

    const importFileForm = useFormik({
        initialValues: fileFormInitialValues,
        validationSchema: Yup.object().shape({
            file: Yup.string().ensure().required('Champ obligatoire'),
        }),
        onSubmit: onImportFileFormSubmit
    });

    useEffect(() => {
        setFetching(true);

        try {

            fetchAuthorizations({
                page: query?.page || 1
            }, () => {
                setFetching(false);
            });

        } catch (e) {
            throw e;
        }

    }, [
        query?.page
    ]);

    if(!authUser.isAdmin && !authUser.isRoot) {
        return <Redirect to="/unauthorize" />
    }

    return (
        <div className="container-fluid">
            <AppBreadcrumb title="Adresses emails autorisées"/>
            {fetching ? <FecthingSpinner /> : (
                <div className="row clearfix">
                    <div className="col-md-12">
                        <button disabled={exporting} className="btn btn-outline-default btn-sm mr-1 font-weight-bold" onClick={onExportFile}>
                            <i className="fa fa-file-excel-o"></i>{' '}
                            <span>{exporting ? 'Export...' : 'Exporter la liste'}</span>
                        </button>
                        <button disabled={downloading} className="btn btn-info btn-sm mr-1 font-weight-bold" onClick={onDownloadFile}>
                            <i className="fa fa-download"></i>{' '}
                            <span>{downloading ? 'Téléchargement...' : 'Télécharger le modèle d\'importation'}</span>
                        </button>
                        <button className="btn btn-primary btn-sm mr-1 font-weight-bold" onClick={() => setModal(state => ({...state, inputForm: true}))}>
                            <i className="fa fa-keyboard-o"></i>{' '}
                            <span>Nouvelle autorisation (Saisie)</span>
                        </button>

                        <button className="btn btn-success btn-sm mr-1 font-weight-bold" onClick={() => setModal(state => ({...state, fileForm: true}))}>
                            <i className="fa fa-upload"></i>{' '}
                            <span>Importer un fichier d'autorisation</span>
                        </button>
                    </div>
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="table table-hover table-custom spacing8">
                                <thead>
                                <tr>
                                    <th colSpan={2}>Utilisateur</th>
                                    <th>Adresse email</th>
                                    <th>-</th>
                                </tr>
                                </thead>
                                <tbody>
                                {emails.map(obj => (
                                    <tr key={obj?.email_id} style={{cursor: "pointer"}} onClick={() => obj?.user ? navigateToUserProfile(obj?.user) : null}>
                                        {obj?.user ? (
                                            <>
                                                <td className="w60">
                                                    <img src={obj?.user?.profile?.prof_picture || MyOpinionLogo} data-toggle="tooltip"
                                                         data-placement="top" title="Avatar Name" alt="Avatar"
                                                         className="w35 rounded"/>
                                                </td>
                                                <td>
                                                    {getUserFullName(obj?.user, true)}{' '}
                                                </td>
                                            </>
                                        ) : (
                                            <>
                                                <td colSpan={2}>Aucun compte associe</td>
                                            </>
                                        )}
                                        <td>{obj?.email_address}</td>
                                        <td>
                                            {authUser?.isRoot && (
                                                <button className="btn btn-danger btn-round btn-sm font-weight-bold"
                                                        onClick={(event) => onDeleteEmail(event, obj)}>
                                                    <i className="fa fa-times"></i>
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="card">
                            <div className="body">
                                <AppPagination
                                    onNavigate={handlePagePaginate}
                                    currentPage={paginationData.currentPage}
                                    hasNext={paginationData.hasNext}
                                    hasPrevious={paginationData.hasPrevious}
                                    totalItems={paginationData.totalItems}
                                    lastPage={paginationData.lastPage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}


            <div className={`modal fade ${modal.inputForm ? 'd-block show' : ''}`} id="exampleModal"  onClick={() => null}>
                <div className="modal-dialog modal-md" role="document">
                    <Form onSubmit={importInputForm.handleSubmit}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Saisie de nouvelles autorisation</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCancelImportInputForm}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {alert && <AppAlert onClose={onClose} type={alert.type}>{alert.message}</AppAlert>}
                                <AppInput
                                    label="Adresse email 1"
                                    name="email_address_1"
                                    value={importInputForm.values.email_address_1}
                                    error={importInputForm.errors.email_address_1}
                                    touched={importInputForm.touched.email_address_1}
                                    onChange={importInputForm.handleChange}
                                    onBlur={importInputForm.handleBlur}
                                    required
                                />

                                <AppInput
                                    label="Adresse email 2"
                                    name="email_address_2"
                                    value={importInputForm.values.email_address_2}
                                    error={importInputForm.errors.email_address_2}
                                    touched={importInputForm.touched.email_address_2}
                                    onChange={importInputForm.handleChange}
                                    onBlur={importInputForm.handleBlur}
                                />

                                <AppInput
                                    label="Adresse email 3"
                                    name="email_address_3"
                                    value={importInputForm.values.email_address_3}
                                    error={importInputForm.errors.email_address_3}
                                    touched={importInputForm.touched.email_address_3}
                                    onChange={importInputForm.handleChange}
                                    onBlur={importInputForm.handleBlur}
                                />

                            </div>
                            <div className="modal-footer">
                                <button type="button" className={`btn btn-round btn-danger font-weight-bold`} disabled={submitting} data-dismiss="modal" onClick={onCancelImportInputForm}>
                                    <span className="fa fa-times"></span>{' '}Annuler
                                </button>
                                <button type="submit" className={`btn btn-round btn-success font-weight-bold`} disabled={submitting}>
                                    <span className="fa fa-save"></span>{' '}{submitting ? 'Encour...' : 'Enregistrer'}
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>

            <div className={`modal fade ${modal.fileForm ? 'd-block show' : ''}`} id="exampleModal"  onClick={() => null}>
                <div className="modal-dialog modal-md" role="document">
                    <Form onSubmit={importFileForm.handleSubmit}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Importation de fichier d'autorisation</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCancelImportFileFormSubmit}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {alert && <AppAlert onClose={onClose} type={alert.type}>{alert.message}</AppAlert>}
                                <FormGroup>
                                    <AppInputFile
                                        name="file"
                                        placeholder={file?.name}
                                        required={true}
                                        error={importFileForm.errors.file}
                                        touched={importFileForm.touched.file}
                                        label="Selectionnez le fichier a importer"
                                        onChange={onFileSelect}
                                        accept=".xlsx"
                                    />
                                </FormGroup>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className={`btn btn-round btn-danger font-weight-bold`} disabled={submitting} data-dismiss="modal" onClick={onCancelImportFileFormSubmit}>
                                    <span className="fa fa-times"></span>{' '}Annuler
                                </button>
                                <button type="submit" className={`btn btn-round btn-success font-weight-bold`} disabled={submitting}>
                                    <span className="fa fa-save"></span>{' '}{submitting ? 'Importation...' : 'Importer'}
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );

};

const mapStateToProps = (state) => ({
    authUser: state.auth.user
})

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizationList);